import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import LogoLinks from '../components/LogoLinks';

import video from '../images/Just-A-Nibble-Video.mp4';
import ServicesMasonry from '../components/ServicesMasonry';

const container = {
   hidden: { opacity: 0 },
   show: {
      opacity: 1,
      transition: {
         staggerChildren: 0.75,
      },
   },
};

const item = {
   hidden: { opacity: 0, y: 25 },
   show: {
      opacity: 1,
      y: 0,
      transition: {
         duration: 0.5,
      },
   },
};

const staff = () => {
   return (
      <Layout>
         <Seo title='Services' />
         <header className='w-full'>
            <div className='relative'>
               <div className='absolute inset-x-0' />
               <div className='relative h-full'>
                  <div className='absolute inset-0'>
                     <StaticImage
                        src='../images/Staff-Just-A-Nibble-Hero.jpg'
                        quality='100'
                        loading='eager'
                        className='h-full w-full object-cover'
                        alt='Just a Nibble Charcuterie board'
                     />
                     <div aria-hidden='true' className='opacity-60 md:opacity-70 absolute inset-0 bg-gradient-to-b from-black to-transparent' />
                  </div>
                  <div className='relative mx-auto max-w-7xl px-4 pt-96 text-center sm:px-6 lg:px-8 pb-36'>
                     <h1 className='mt-12 text-5xl font-hanley-script relative sm:text-6xl lg:text-8xl'>
                        <span className='block header'>Services</span>
                     </h1>
                     <motion.hr
                        initial={{ width: 0, opacity: 0 }}
                        whileInView={{ width: 200, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='border-brand border rounded w-1/2 mx-auto my-3'
                     />
                     <motion.h2
                        initial={{ y: 10, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        className='text-white text-base md:text-xl uppercase tagline'
                     >
                        Top-notch chefs and staff
                     </motion.h2>
                  </div>
               </div>
            </div>
         </header>
         <HowItWorks />
         <Team />
         <Packages />
         <ServicesMasonry />
         <div className='aspect-video relative'>
            <video autoPlay muted loop playsInline className='w-full object-cover h-full object-center block aspect-video absolute inset-0'>
               <source src={video} alt='A compilation video of Just A Nibble catering events' type='video/mp4' />
            </video>
         </div>
         <LogoLinks />
      </Layout>
   );
};

const HowItWorks = () => {
   return (
      <section className='relative w-full py-14'>
         <div className='absolute inset-0'>
            <StaticImage src='../images/slate.jpg' alt='Slate Background' quality='50' className='h-full w-full object-cover' />
            <div aria-hidden='true' className='opacity-60 md:opacity-80 absolute inset-0 bg-black' />
         </div>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-White.png'
               className='opacity-50 '
               placeholder='tracedSVG'
               alt='Just a Nibble Utensils Icon'
               quality={100}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               How It All Works
            </motion.p>
         </div>
         <div className='relative z-30 max-w-4xl pt-14 mx-auto'>
            <div className='px-8 mx-auto'>
               <motion.div
                  variants={container}
                  initial='hidden'
                  animate='show'
                  className='prose text-center text-slate-100 space-y-4 proxima leading-loose mx-auto'
               >
                  <motion.p variants={item}>
                     Delicious food created by us, served at your private event. Whether it be an apartment, home, venue, office space or our Park
                     Slope Parlor, our specialty is transforming unique spaces into event spaces, and filling the air with the delicious smells &
                     flavors of our food.
                  </motion.p>
                  <motion.p variants={item}>
                     Picture your favorite main course from restaurant A, appetizers from restaurant B, dessert from restaurant C. We can
                     custom-create your favorites, bringing those tastes to your event, creating something special and unique that matches you!
                  </motion.p>
                  <motion.p variants={item}>
                     Providing top-notch on-site service, that leaves you and your guests requesting our staff and chefs for all your event needs.
                  </motion.p>
                  <motion.p variants={item}>
                     Specializing in:{' '}
                     <b>
                        Baby & Wedding Showers, Cocktail Parties, Fundraisers, Business Launches, Holiday Parties, Memorials, and Gatherings Large and
                        Small.
                     </b>
                  </motion.p>
               </motion.div>
            </div>
         </div>
         <motion.hr
            initial={{ width: 0, opacity: 0 }}
            whileInView={{ width: 200, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className='border-brand relative border z-50 rounded mx-auto mt-6'
         />
      </section>
   );
};

const Team = () => {
   return (
      <section className='relative w-full py-14'>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-Brand.png'
               alt='Just a Nibble Utensils Icon'
               className=''
               placeholder='tracedSVG'
               quality={100}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand2 font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               The Team
            </motion.p>
         </div>
         <div className='w-full relative mx-auto py-14'>
            <div className='max-w-3xl mx-auto my-6'>
               <StaticImage src='../images/Staff-Just-A-Nibble-Team-Hero.jpg' alt='Just a Nibble chefs and owner' />
            </div>
            <div className='relative z-30 max-w-4xl mx-auto'>
               <div className='px-8'>
                  <motion.div
                     variants={container}
                     initial='hidden'
                     animate='show'
                     className='space-y-4 prose mx-auto proxima text-slate-800 text-center leading-loose'
                  >
                     <motion.p variants={item}>
                        Our special events team has years of experience performing in the high-end NYC catering scene. Using Insider methods and
                        creativity, we turn “catered food”, into awe-inspiring plates, platters, stations, & passed hors d’oeuvres, that your guests
                        won’t soon forget.
                     </motion.p>
                     <motion.p variants={item}>
                        We typically send an event captain and chef, along with the appropriate amount of additional staff to complete your event
                        team. Whether that be front of house, kitchen, or both, we’ll have you covered.
                     </motion.p>
                     <motion.p variants={item}>
                        This team will set up the space, prep for the event, take care of all the food and beverage needs throughout the event, as
                        well as break down and restore the space following the event’s conclusion.
                     </motion.p>
                  </motion.div>
               </div>
            </div>
         </div>
         <motion.hr
            initial={{ width: 0, opacity: 0 }}
            whileInView={{ width: 200, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className='border-brand border rounded relative z-50 mx-auto'
         />
      </section>
   );
};

const Packages = () => {
   return (
      <section className='relative w-full py-14'>
         <div className='absolute inset-0'>
            <StaticImage src='../images/slate.jpg' alt='Slate Background' quality='50' className='h-full w-full object-cover' />
            <div aria-hidden='true' className='opacity-60 md:opacity-80 absolute inset-0 bg-black' />
         </div>
         <div className='relative flex items-center justify-center py-2 w-full mx-auto'>
            <StaticImage
               src='../images/Logo-Just-A-Nibble-Utensils-White.png'
               className='opacity-50 '
               placeholder='tracedSVG'
               alt='Just a Nibble Utensils Icon'
               quality={100}
               height={125}
            />
            <motion.p
               initial={{ opacity: 0 }}
               whileInView={{ opacity: 1 }}
               transition={{ duration: 1, delay: 1 }}
               viewport={{ once: true }}
               className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand font-hanley-sans text-center tracking-widest uppercase font-boldest z-20'
            >
               Extras
            </motion.p>
         </div>
         <div className='relative z-30 max-w-4xl mx-auto pt-14'>
            <div className='px-8 mx-auto'>
               <motion.div
                  variants={container}
                  initial='hidden'
                  animate='show'
                  style={{ marginRight: 0 }}
                  className='text-center text-slate-100 space-y-4 proxima leading-loose'
               >
                  <div className='flex flex-row items-center justify-center'>
                     <hr className='border-brand border rounded w-8' />
                     <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Beverage Packages</h2>
                     <hr className='border-brand border rounded w-8' />
                  </div>
                  <motion.p className='prose mx-auto text-slate-100 leading-loose' variants={item}>
                     We are able to provide a wide range of beverage options for events, including a full bar, specialty cocktails, wine, and beer. We
                     are also happy to assist with determining the appropriate quantity of beverages for your event should you want to provide your
                     own product. In addition to the types of beverages we offer, we also provide trained and professional bartenders to serve them.
                     We understand that every event is unique, so we are flexible and able to accommodate specific needs and preferences. If you have
                     any special requests, such as non-alcoholic options, please let us know and we will do our best to accommodate them.
                  </motion.p>
               </motion.div>
            </div>

            <div className='px-8 mx-auto mt-8'>
               <motion.div
                  variants={container}
                  initial='hidden'
                  animate='show'
                  style={{ marginRight: 0 }}
                  className='text-center text-slate-100 space-y-4 proxima leading-loose'
               >
                  <div className='flex flex-row items-center justify-center'>
                     <hr className='border-brand border rounded w-8' />
                     <h2 className='text-2xl md:text-4xl font-hanley-script text-slate-100 mx-2'>Rentals</h2>
                     <hr className='border-brand border rounded w-8' />
                  </div>
                  <motion.p className='prose mx-auto text-slate-100 leading-loose' variants={item}>
                     For larger events and weddings, we are happy to coordinate your rental needs with one of our trusted rental partners. For smaller
                     gatherings, we have a wide range of items available and are happy to provide them at no additional cost to you. This can help
                     simplify the event planning process and allow you to focus on other aspects of your event. Please let us know what you need and
                     we will do our best to accommodate your requests.
                  </motion.p>
               </motion.div>
            </div>
            <motion.hr
               initial={{ width: 0, opacity: 0 }}
               whileInView={{ width: 200, opacity: 1 }}
               transition={{ duration: 1 }}
               viewport={{ once: true }}
               className='border-brand relative border z-50 rounded mx-auto mt-12'
            />
         </div>
      </section>
   );
};

// const ImageGrid = () => {
//    return (
//       <section className='relative'>
//          <div className='grid-cols-2 sm:grid-cols-4 grid w-full'>
//             <div className='overflow-hidden ease-in-out'>
//                <StaticImage
//                   className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
//                   alt='Just a Nibble waitstaff'
//                   src='../images/Staff-Just-A-Nibble-StaffImg1.jpg'
//                   objectPosition='50% 0%'
//                />
//             </div>
//             <div className='overflow-hidden ease-in-out'>
//                <StaticImage
//                   className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
//                   alt='Just a Nibble waitstaff'
//                   src='../images/Staff-Just-A-Nibble-StaffImg2.jpg'
//                />
//             </div>

//             <div className='overflow-hidden ease-in-out'>
//                <StaticImage
//                   className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
//                   alt='Just a Nibble waitstaff'
//                   src='../images/Staff-Just-A-Nibble-StaffImg3.jpg'
//                   objectPosition='50% 75%'
//                />
//             </div>
//             <div className='overflow-hidden ease-in-out'>
//                <StaticImage
//                   className='aspect-square hover:scale-110 hover:transition-all hover:duration-1000'
//                   alt='Just a Nibble chef'
//                   src='../images/Staff-Just-A-Nibble-StaffImg4.jpg'
//                   objectPosition='50% 85%'
//                />
//             </div>
//          </div>
//          <div aria-hidden='true' className='opacity-20 absolute inset-0 bg-black' />
//       </section>
//    );
// };

export default staff;
