import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const ServicesMasonry = () => {
   return (
      <section className='relative w-full bg-slate-200'>
         <div className='max-w-6xl py-4 px-8 md:px-4 mx-auto items-center'>
            <div class='lg:px-32 px-4 py-8 mx-auto items-center relative z-40'>
               <div class='grid grid-cols-4 grid-flow-row gap-2'>
                  {/* Full Grid */}
                  <div class='w-full row-span-2 col-span-2 md:col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-100.jpg'
                        alt='Just a Nibble Catering Food and Event'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-101.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:col-span-2 col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-102.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:row-span-1 row-span-2 md:col-span-1 col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-103.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:col-span-1 col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-104.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-105.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2 md:row-span-1 md:col-span-1'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-106.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2 md:row-span-1'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-107.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  {/* Split */}
                  <div className='w-full col-span-4 row-span-2 md:row-span-2 md:col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-108.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div className='w-full col-span-4 md:col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-109.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div className='w-full col-span-2 row-span-1'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-110.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  {/* Full Grid */}
                  <div class='w-full row-span-3 col-span-2 md:col-span-1'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-111.jpg'
                        alt='Just a Nibble Catering Food and Event'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-112.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:col-span-1 row-span-2 md:row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-113.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:col-span-4 row-span-2 md:row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-114.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-115.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2 md:row-span-1'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-116.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:col-span-1 col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-117.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full md:col-span-1 col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-118.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  {/* Full Grid-2 */}
                  <div class='w-full row-span-1 col-span-2 md:col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-119.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full row-span-1 col-span-2 md:col-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-120.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-121.jpg'
                        alt='Just a Nibble Catering Food and Event'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-122.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-123.jpg'
                        alt='Just a Nibble Catering Food and Event'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                     />
                  </div>
                  <div class='w-full col-span-2 row-span-2'>
                     <StaticImage
                        src='../images/ServicesMasonry/Services-Just-A-Nibble-124.jpg'
                        class='inset-0 h-full w-full object-cover object-center rounded'
                        alt='Just a Nibble Catering Food and Event'
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default ServicesMasonry;
